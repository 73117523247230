import React, { useEffect, useState } from 'react';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor as EditorDraft } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Add } from '@mui/icons-material';
import './Editor.css';

const ExpandableEditorComponent = (props) => {
    const [editorState, setEditorState] = useState(
        () => EditorState.createWithContent(
            ContentState.createFromBlockArray(
                htmlToDraft(props.val ? props.val : "<p> </p>")
            )
        ),
    );

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState(""); 

    // update editor state when props.val changes
    useEffect(() => {
        setEditorState(EditorState.createWithContent(
            ContentState.createFromBlockArray(
                htmlToDraft(props.val ? props.val : "<p> </p>")
            )
        ));
    }, [typeof props.val]);

    const openDialog = () => {
        const contentHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setDialogContent(contentHTML);
        setDialogOpen(true); 
    };

    const closeDialog = () => {
        setDialogOpen(false); 
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={10}></Grid>
                <Grid item xs={2}>
                    <Button sx={{ mt: 3 }} onClick={openDialog}>
                        {<Add />}
                    </Button>
                </Grid>
            </Grid>
            <EditorDraft
                editorState={editorState}
                onEditorStateChange={(e) => {
                    setEditorState(e);
                    props.onValueChange(draftToHtml(convertToRaw(e.getCurrentContent())));
                }}
                toolbarClassName='toolbarClassName'
                wrapperClassName='wrapperClassName'
               editorClassName="editor-height"
               editorStyle={{ height: '400px' }}
            />

            <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="md" fullWidth>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: dialogContent,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ExpandableEditorComponent;
