import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  DialogContent,
  Grid,
  InputLabel,
  Select,
  Slide,
  TextField,
  Typography,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  FormControl
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../../../form/TextInput";
import DateInput from "../../../form/DateInput";
import SelectX from "../../../form/SelectX";
import { Project } from "../../../../api/Endpoints/Project";
import DynamicChip from "../../../../utils/DynamicChip";
import { FormHelpers } from "../../../../helpers/FormHelpers";
import { FollowUp } from "../../../../api/Endpoints/FollowUp";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { Task } from "../../../../api/Endpoints/Task";
import { Activities } from "../../../../api/Endpoints/Activities";
import { ErrorMessage } from "@hookform/error-message";
import LoadingScreen from "../../../form/progress";
import { Users } from "../../../../api/Endpoints/Users";
import { Auth } from "../../../../api/Endpoints/Auth";
import ReactSelector from "react-select";
import PageHeader from "../../common/page-header";
import { Employee } from "../../../../api/Endpoints/Employee";
import ExpandableEditor from "../../../form/ExpandableEditor";
import TextEditor from "../../../form/ExpandableEditor";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({
  title: yup.string().required(),
  task: yup.string(),
  projects_id: yup.object().nullable()
});

const CreateReport = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset
  } = useForm({ resolver: yupResolver(scheme) });

  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [recentProjects, setRecentProjects] = useState([]);
  const [currentUser, setCurrentUser] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [taskId, setTaskId] = useState();
  const [durationMinutes, setDurationMinutes] = React.useState();
  const [durationHours, setDurationHours] = React.useState();
  const [taskStatus, setTaskStatus] = useState([
    "Not Started",
    "In progress",
    "Review pending",
    "In review"
  ]);

  //component variables
  const [formButtonStatus, setFormButtonStatus] = useState({
    label: "Submit",
    loading: false,
    disabled: false
  });
  const [alerts, setAlerts] = useState({
    type: "",
    message: "",
    active: false
  });
  const [period, setPeriod] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [employees, setEmployees] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState(1);

  const states = [
    { id: 1, name: "Draft" },
    { id: 2, name: "Ready" },
    { id: 3, name: "Publish" }
  ];
  const handlePriorityChange = (id) => {
    setSelectedPriority(id);
  };
  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  const fetchProjects = (e) => {
    return Project.get({ keyword: e }).then((response) => {
      if (typeof response.data.data.data !== "undefined") {
        return response.data.data.data;
      } else {
        return [];
      }
    });
  };

  const fetchTasks = (id) => {
    Task.get({ assigned_to: id, status: "Not Started" }).then((response) => {
      if (typeof response.data.data.data !== "undefined") {
        console.log();
        setTasks(response.data.data.data);
        return response.data.data.data;
      } else {
        return [];
      }
    });
  };

  const handleMinuteChange = (e) => {
    if (e.target.value.length <= 2) {
      setDurationMinutes(e.target.value);
    }
  };
  const handleHourChange = (e) => {
    if (e.target.value.length <= 2) {
      setDurationHours(e.target.value);
    }
  };

  const onSubmit = (data) => {
    console.log(data);
    setFormButtonStatus({ ...formButtonStatus, loading: true });
    setAlerts({
      active: false,
      message: "Internal server error",
      type: "error"
    });

    // let dataToSubmit = {
    //     // id: props.editId,
    //     projects_id: data.projects_id?.id,
    //     title: data.title,
    //     description: data.description,
    //     due_date: data.due_date,
    //     assigned_to_id: data.assign_to?.id,
    //     reviewer_id: data.reviewer_id?.id,
    //     project_tasks_id: taskId,
    //     duration_hours: durationHours,
    //     duration_minutes: durationMinutes

    // };

    // let action;
    // if (props.editId > 0) {
    //     console.log("dataToSubmit", dataToSubmit);
    //     action = Activities.update(dataToSubmit);
    // } else {
    //     action = Activities.add(dataToSubmit);
    // }

    // action.then(response => {
    //     console.log(response);
    //     setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
    //     setAlerts({ active: true, message: response.data.message, type: response.data.status })
    //     setFormButtonStatus({ label: "Create", loading: false, disabled: false });
    //     toast.success(response.data.message)
    //     props.onUpdate();
    //     props.setEditId()
    //     setOpen(false);
    //     setTimeout(() => { setAlerts({}) }, 2000)
    // }).catch(errors => {
    //     console.log(errors);
    //     toast.error("Internal server error");
    //     setAlerts({ active: true, message: "Internal server error", type: "error" })
    //     setFormButtonStatus({ label: "Create", loading: false, disabled: false });
    // })
  };

  // const fetchActivityDetails = async () => {
  //     setLoading(true)
  //     let task = await Activities.getDetails({ id: props.editId });
  //     if (task.data.status === "success") {
  //         let data = task.data.data;
  //         setValue('title', data.title);
  //         setValue('description', data.description);
  //         // setValue('durationMinutes', data.duration_minutes);
  //         // setValue('durationHours', data.duration_hours);
  //         setValue('start_time', data.start_time);
  //         setValue('end_time', data.end_time);
  //         setValue('projects_id', { id: data.project?.id, name: data.project?.name });
  //         setValue('task', data.project_task?.title);
  //         setSelectedPriority(data.priority);
  //         setDurationMinutes(data.duration_minutes)
  //         setDurationHours(data.duration_hours)
  //     }
  //     setLoading(false)
  // };

  const fetchRecentProjects = () => {
    Users.recentProjects().then((response) => {
      console.log(response);
      setRecentProjects(response.data.data);
    });
  };

  const fetchUser = async () => {
    const accessToken = window.localStorage.getItem("accessToken");
    const user = await Auth.me(accessToken);
    setCurrentUser(user.data.data.id);
    console.log(user);
    // setValue('reviewer_id', { id: user.data.data.id, name: user.data.data.name })
    fetchTasks(user.data.data.id);
  };

  // console.log(currentUser);
  useEffect(() => {
    setRefresh(Math.random());
  }, [watch("projects_id")]);

  useEffect(() => {
    fetchRecentProjects();
    fetchUser();
  }, []);

  const fetchEmployees = (e) => {
    console.log(e);
    Employee.get({ keyword: e, limit: 100 }).then((response) => {
      // console.log(response.data.data.data);
      if (typeof response.data.data.data !== "undefined") {
        setEmployees(response.data.data.data);
      } else {
        return [];
      }
    });
  };

  // useEffect(() => {
  //     if (parseInt(props.editId) > 0) {

  //         fetchUser()
  //         setOpen(true);
  //     } else if (parseInt(props.editId) === 0) {
  //         setOpen(true);
  //         fetchUser()
  //     }

  // }, [props.editId])

  return (
    <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
      <Box
        sx={{
          width: "100%",
          maxWidth: 1200,
          p: 3
        }}
      >
        <PageHeader title={"Create Activity"} />

        {!loading ? (
          <>
            <div>
              <form onSubmit={handleSubmit(onSubmit)} style={{ margin: 3 }}>
                <ErrorMessage
                  errors={errors}
                  name="multipleErrorInput"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <p key={type}>{message}</p>
                    ))
                  }
                />

   
                <Grid container spacing={2} sx={{ display: "flex", mt: 2 }}>
                <Grid sx={{ mt: 2 }} item xs={4}>
                  <InputLabel sx={{ color: "black" }}>Project</InputLabel>
                  <SelectX
                    // label={"Choose project"}
                    options={fetchProjects}
                    control={control}
                    error={
                      errors?.projects_id?.id
                        ? errors?.projects_id?.id?.message
                        : false
                    }
                    error2={
                      errors?.projects_id?.message
                        ? errors?.projects_id?.message
                        : false
                    }
                    name={"projects_id"}
                    defaultValue={watch("projects_id")}
                  />
                </Grid>
                <Grid sx={{ mt: 2 }} item xs={4}>
                  <InputLabel sx={{ color: "black" }}>Reviewer</InputLabel>
                  <SelectX
                    options={fetchEmployees}
                    control={control}
                    name={"reviewer"}
                    value={watch("reviewer")}
                  />
                </Grid>
                <Grid sx={{ mt: 5 }} item xs={4}>
                  {states.map((obj) => {
                    return (
                      <DynamicChip
                        key={obj.id}
                        name={obj.name}
                        id={obj.id}
                        active={selectedPriority}
                        onChipCLick={handlePriorityChange}
                      />
                    );
                  })}
                </Grid>

                </Grid>
                <Grid container spacing={2} sx={{ display: "flex", mt: 2 }}>
                  <Grid item xs={12} sm={4}>
                    <InputLabel sx={{ color: "black" }}>Period</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="period-select-label"
                        value={period}
                        onChange={handlePeriodChange}
                        displayEmpty
                        sx={{ color: "gray", height: "35px" }}
                      >
                        <MenuItem value="" disabled sx={{ color: "grey" }}>
                          Period
                        </MenuItem>
                        <MenuItem value="Weekly">Weekly</MenuItem>
                        <MenuItem value="Monthly">Monthly</MenuItem>
                        <MenuItem value="Quarterly">Quarterly</MenuItem>
                        <MenuItem value="Annual">Annual</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <InputLabel sx={{ color: "black" }}>End Date</InputLabel>
                    <FormControl fullWidth>
                      <DateInput
                        control={control}
                        name={"end_date"}
                        value={watch("end_date")}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputLabel sx={{ color: "black" }}>Type</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="type-select-label"
                        value={type}
                        onChange={handleTypeChange}
                        displayEmpty
                        sx={{ color: "gray", height: "35px" }}
                      >
                        <MenuItem value="" disabled sx={{ color: "gray" }}>
                          Type
                        </MenuItem>
                        <MenuItem value="Internal">Internal</MenuItem>
                        <MenuItem value="Client">Client</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
           
                <Grid sx={{ mt: 2 }} item xs={12}>
                  
                      <InputLabel sx={{ color: "black" }}>
                        Activity Summary
                      </InputLabel>
                  
                  <TextEditor
                    emoji={false}
                    val={watch("activity_summary")}
                    onValueChange={(e) => setValue("activity_summary", e)}
                    title={" Activity Summary"}
                  />
                </Grid>
                <Grid sx={{ mt: 2 }} item xs={12}>
                  
                      <InputLabel sx={{ color: "black" }}>
                      Concern
                      </InputLabel>
                  
                  <TextEditor
                    emoji={false}
                    val={watch("concern")}
                    onValueChange={(e) => setValue("concern", e)}
                    title={"Concern"}
                  />
                </Grid>
                <Grid sx={{ mt: 2 }} item xs={12}>
                  
                      <InputLabel sx={{ color: "black" }}>
                        Upcoming Plan
                      </InputLabel>
                  
                  <TextEditor
                    emoji={false}
                    val={watch("upcoming_plan")}
                    onValueChange={(e) => setValue("upcoming_plan", e)}
                    title={"Upcoming Plans"}
                  />
                </Grid>

            

                <Grid sx={{ mt: 2 }} item xs={12}>
                  <LoadingButton
                    loading={formButtonStatus.loading}
                    disabled={formButtonStatus.disabled}
                    variant="outlined"
                    type="submit"
                  >
                    {formButtonStatus.label}
                  </LoadingButton>
                </Grid>
              </form>
            </div>
          </>
        ) : (
          <Box className="loadingScreen">
            <LoadingScreen />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CreateReport;
