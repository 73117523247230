import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Slide,
  FormControl,
  Select,
  MenuItem
} from '@mui/material'
import ReactSelector from 'react-select';
import * as yup from 'yup'
import { useForm,Controller } from 'react-hook-form'
import TextInput from '../../form/TextInput'
import { LoadingButton } from '@mui/lab'
import toast from 'react-hot-toast'
import SelectX from '../../form/SelectX'
import moment from 'moment'
import { ErrorMessage } from '@hookform/error-message'
import AddIcon from '@mui/icons-material/Add'
import CurrentDateInput from '../../form/CurrenDateInput'
import { Project } from '../../../api/Endpoints/Project'
import { Employee } from '../../../api/Endpoints/Employee'


const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})


const statusOptions = [
  'Requested',
  'Rejected',
  'Accepted',
  'Design In Progress',
  'UI Conversion In Progress',
  'Integration in progress',
  'Development Completed',
  'Approved by Client',
  'Live'
]

const Create = props => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset
  } = useForm({})
  const [selectedPriority, setSelectedPriority] = useState(3)
  const [open, setOpen] = React.useState(false)
  const [refresh, setRefresh] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [selectedScopes, setSelectedScopes] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([]);

  const categories = [
    { id: "finance", name: "Finance" },
    { id: "health", name: "Health" },
    { id: "education", name: "Education" },
  ];

  const selectCategory = (selectedOptions) => {
    setSelectedCategories(selectedOptions || []);
  };

  const handleClickOpen = () => {
    props.onNew()
  }
  const handleClose = () => {
    props.setEditId(undefined)
    setOpen(false)
  }

  //component variables
  const [formButtonStatus, setFormButtonStatus] = useState({
    label: 'Submit',
    loading: false,
    disabled: false
  })
  const [alerts, setAlerts] = useState({
    type: '',
    message: '',
    active: false
  })

  const fetchEmployees = async keyword => {
    const response = await Employee.get({ keyword: keyword })
    return response.data.data?.data
  }

  const onSubmit = data => {
    setFormButtonStatus({ ...formButtonStatus, loading: true })
    setAlerts({
      active: false,
      message: 'Internal server error',
      type: 'error'
    })

    let date = moment(data.start_date).format('YYYY-MM-DD')
    let enddate = moment(data.end_date).format('YYYY-MM-DD')
    let duedate = moment(data.due_date).format('YYYY-MM-DD')

    let dataToSubmit = {
      id: props.editId,
      name: data.project_name,
      description: data.description,
      start_date: date,
      due_date: duedate,
      accounts_id: data.client_id?.id,
      end_date: enddate,
      country_id: data.country?.id,
      scopes: selectedScopes
    }

    let action

    if (props.editId > 0) {
      console.log('dataToSubmit', dataToSubmit)
      action = Project.update(dataToSubmit)
    } else {
      action = Project.add(dataToSubmit)
    }
    action
      .then(response => {
        setFormButtonStatus({
          label: 'Submitted',
          loading: false,
          disabled: true
        })
        setAlerts({
          active: true,
          message: response.data.message,
          type: response.data.status
        })
        setFormButtonStatus({
          label: 'Create',
          loading: false,
          disabled: false
        })
        toast.success(response.data.message)
        props.onUpdate()
        props.setEditId()
        setOpen(false)
        setTimeout(() => {
          setAlerts({})
        }, 2000)
      })
      .catch(errors => {
        console.log(errors)
        toast.error('Internal server error')
        setAlerts({
          active: true,
          message: 'Internal server error',
          type: 'error'
        })
        setFormButtonStatus({
          label: 'Create',
          loading: false,
          disabled: false
        })
      })
  }

  const fetchDetails = async () => {
    const response = await Project.getDetails({ project_id: props.editId })
    if (response.data.status === 'success') {
      const data = response.data.data
      console.log(data)
      setSelectedScopes(data.scopes?.map(item => item.scope))
      setValue('project_name', data.name)
      setValue('description', data.description)
      if (data.accounts_id) {
        setValue('client_id', {
          id: data.accounts_id,
          name: data.account?.account_name
        })
      }
      if (!data.accounts_id) {
        setValue('client_id', '')
      }
      if (data.country_id) {
        setValue('country', {
          id: data.country?.id,
          name: data.country?.name
        })
      }
      setValue('start_date', data?.start_date)
    }
  }

  const fetchProjects = e => {
    return Project.get({ keyword: e }).then(response => {
      if (typeof response.data.data.data !== 'undefined') {
        return response.data.data.data
      } else {
        return []
      }
    })
  }
  useEffect(() => {
    if (parseInt(props.editId) > 0) {
      fetchDetails()
      setOpen(true)
    } else if (props.editId === 0) {
      console.log('truee')
      setOpen(true)
    }
  }, [props.editId])

  useEffect(() => {
    setRefresh(Math.random())
  }, [watch('client_id')])

  return (
    <div>
      <Button variant='contained' onClick={handleClickOpen}>
        {props.icon ? (
          <AddIcon
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          />
        ) : (
          'Add Website'
        )}
      </Button>

      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: '50%',
            height: '100%',
            position: 'fixed',
            right: 0,
            top: 0,
            bottom: 0,
            m: 0,
            p: 0,
            borderRadius: 0,
            maxHeight: '100%'
          }
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          {parseInt(props.editId) > 0 ? 'Edit Website' : 'Add Website'}
        </DialogTitle>
        <DialogContent>
          {!loading ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <ErrorMessage
                errors={errors}
                name='multipleErrorInput'
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <p key={type}>{message}</p>
                  ))
                }
              />

              <Grid container>
                <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
              
                  <SelectX
                  label="Select Project"
                    options={fetchProjects}
                    control={control}
                    error={
                      errors?.projects_id?.id
                        ? errors?.projects_id?.id?.message
                        : false
                    }
                    error2={
                      errors?.projects_id?.message
                        ? errors?.projects_id?.message
                        : false
                    }
                    name={'projects_id'}
                    defaultValue={watch('projects_id')}
                  />
                </Grid>
                <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
                  <TextInput
                    name='title'
                    label='Title'
                    control={control}
                    value={watch('title')}
                  />
                </Grid>
                <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
                  <TextInput
                    name='url'
                    label='URL'
                    control={control}
                    value={watch('url')}
                  />
                </Grid>
                <Grid 
  container 
  spacing={2} 
  alignItems="center"
  sx={{p:1}}
>
  <Grid item xs={12} sm={4}>
    <SelectX
      label="Owner"
      options={fetchEmployees}
      control={control}
      name="owner"
      defaultValue={watch('owner')}
    />
  </Grid>
  <Grid item xs={12} sm={4}>
    <CurrentDateInput
      control={control}
      name="launch_date"
      label="Launch Date"
      value={watch('launch_date')}
    />
  </Grid>
  <Grid item xs={12} sm={4}>
      <InputLabel id="status-label" sx={{mb:"2"}}>Status</InputLabel>
    <FormControl fullWidth>
      <Controller
        name="status"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Select
            {...field}
            labelId="status-label"
            label="Status"
            size='small'
            sx={{p:2,height:"35px"}}
          >
            {statusOptions.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  </Grid>
</Grid>

              </Grid>
              <Grid container spacing={2} sx={{ display: 'flex', p: 1 }}>
                <Grid item xs={12} sm={6}>
      <InputLabel id="status-label">Hosted By</InputLabel>
                <FormControl fullWidth>
      <Controller
        name="status"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Select
            {...field}
           
            size='small'
            sx={{p:2,height:"35px"}}
          >
             <MenuItem value="Client">Client</MenuItem>
             <MenuItem value="SpiderWorks ">SpiderWorks </MenuItem>
             <MenuItem value="Third Party">Third Party</MenuItem>
          </Select>
        )}
      />
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
      <InputLabel id="status-label">Type</InputLabel>
                <FormControl fullWidth>
      <Controller
        name="type"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Select
            {...field}
           
            size='small'
            sx={{p:2,height:"35px"}}
          >
             <MenuItem value="Website">Website</MenuItem>
             <MenuItem value="Landing Page">Landing Page </MenuItem>
          </Select>
        )}
      />
                </FormControl>
                </Grid>
              </Grid>
              <Grid sx={{ p: 1 }} item xs={12} sm={12}>
               <TextInput
                name="technologies"
                label='Technologies'
                control={control}
                 value={watch('technologies')}
               />
              </Grid>

              <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
                <TextInput
                  name='hosting_platform'
                  label='Hosting Platform'
                  control={control}
                  value={watch('hosting_platform')}
                />
              </Grid>
              <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
      <InputLabel
        sx={{
          color: "black",
          fontWeight: 500,
          fontSize: "14px",
          pb: 0.5,
        }}
      >
        Choose Category
      </InputLabel>
      <ReactSelector
        placeholder="Category"
        isMulti
        styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
        options={categories}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        value={selectedCategories}
        name="categories"
        isClearable={false}
        onChange={selectCategory}
      />
      {/* <div>
        <strong>Selected Categories:</strong>{" "}
        {selectedCategories.map((category) => category.name).join(", ")}
      </div> */}
    </Grid>
    <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
      <TextField
      value={watch("description")}
      name="description"
      label="Description"
      multiline
      rows={3}
      />
    </Grid>

              <Grid sx={{ p: 1, mb: 3 }} item xs={12}>
                <LoadingButton
                  loading={formButtonStatus.loading}
                  disabled={formButtonStatus.disabled}
                  variant='outlined'
                  type='submit'
                >
                  {formButtonStatus.label}
                </LoadingButton>
              </Grid>
            </form>
          ) : (
            <>Loading...</>
          )}
        </DialogContent>
        {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
      </Dialog>
    </div>
  )
}

export default Create
