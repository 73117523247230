import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Backdrop, Button, Card, CircularProgress, FormControl, Grid, MenuItem, Popover, Select, TextField } from "@mui/material";
import Details from './Tabs/Details'
import AddTeamMember from './actions/addTeamMember';
import Addmembers from './actions';
import Documents from './Tabs/Documents/Documents';
import Notes from './Tabs/Notes'
import Tasks from './Tabs/Tasks'
import WorkRequests from './Tabs/workRequests'
import { useEffect } from 'react';
import { Project } from '../../../api/Endpoints/Project';
import { useState } from 'react';
import Activity from './Tabs/activity'
import Meetings from './Tabs/Meetings'
import { ArrowDropDown, PinDrop } from '@material-ui/icons';
import Calender from './Tabs/Calender';
import Keywords from './Tabs/Keywords';
import WorkOrders from './Tabs/WorkOrders';
import Invoices from './Tabs/Invoices';
import SiteVisits from './Tabs/SiteVisits';
import Reports from './Tabs/Reports';
import { Close } from '@mui/icons-material';
import WebsiteDevelopment from './Tabs/websiteDevelopment/WebsiteDevelopment';
import { Task } from '../../../api/Endpoints/Task';
import { WorkRequest } from '../../../api/Endpoints/WorkRequest';
import Deliverables from './Tabs/Deliverables/index';
import ScheduleIndex from './Tabs/Schedule';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs({ CloseTab, projectID, fetchTable, setSearchKeyword }) {
    const [value, setValue] = React.useState(0);
    const [ProjectName, setProjectName] = useState('')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [scope, setScope] = useState()
    const [stickyNote, setStickyNote] = useState([])



    const [eightTab, setEightTab] = useState({
        label: 'Document',
        id: scope ? 8 : 7
    })

    useEffect(() => {
        fetchDetails();
    }, [projectID]);

    const fetchDetails = () => {
        Project.getDetails({ project_id: projectID }).then(response => {
            const project = response.data.data.name
            setProjectName(project)
            setStickyNote(response?.data?.data?.notes)
        })
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        setSearchKeyword()
        fetchTable()
        CloseTab()
    }

    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const [dropDownValueTab, setdropDownValueTab] = useState(0)
    const handlePopoverChange = (index) => {
        setdropDownValueTab(index)
        setValue(allTabs.length - 1);
        handleClosePopover()
    };
    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const id = anchorEl ? 'popover-basic' : undefined;

    const getScope = (data) => {
        console.log(data);
    }
    const [taskCount, setTaskCount] = useState(0)
    const [noteCount, setNoteCount] = useState(0)
    const [memberCount, setMemberCount] = useState(0)
    const [workRcount, setWorkRcount] = useState(0)
    const fetchTaskCount = () => {
        Task.get({ projects_id: projectID }).then(response => {
            // console.log(response.data.data);
            setTaskCount(response.data.data.total);
        }).catch(error => {
            console.log(error);
        })
    }
    const fetchNoteCount = async () => {
        const response = await Project.getNotes({ projects_id: projectID })
        if (response.data.status === "success") {
            // console.log(response.data.data);
            setNoteCount(response.data.data.total)
        }
    }
    const fetchMemberCount = async () => {
        try {
            const response = await Project.getEmployees({ projects_id: projectID });
            if (response.data.status === "success") {
                setMemberCount(response?.data?.data?.total)
            }
        } catch (error) {
            console.error("Error fetching list:", error);
        }
    };
    const fetchWorkRcount = () => {
        WorkRequest.get({ projects_id: projectID }).then(response => {
            setWorkRcount(response.data.data.total);
        })
    }

    useEffect(() => {
        fetchTaskCount()
        fetchNoteCount()
        fetchMemberCount()
        fetchWorkRcount()
    }, [])

    const dropDownTabs = [
        { label: "Document", component: <Documents /> },
        { label: "Calendar", component: <Calender /> },
        { label: "Keywords", component: <Keywords /> },
        { label: "Work Orders", component: <WorkOrders projectID={projectID} ProjectName={ProjectName} /> },
        { label: "Invoices", component: <Invoices /> },
        { label: "Site Visits", component: <SiteVisits /> },
        { label: "Reports", component: <Reports /> },
        { label: "Deliverables", component: <Deliverables /> },
        { label: "Schedule", component: <ScheduleIndex projectID={projectID} /> },
    ];

    const allTabs = [
        { label: "Details", component: <Details scope={scope} getScope={setScope} setProjectName={setProjectName} projectID={projectID} /> },
        ...(scope ? [{ label: "Website Management", component: <WebsiteDevelopment projectID={projectID} /> }] : []),
        { label: `Work Requests (${workRcount})`, component: <WorkRequests setCount={setWorkRcount} projectID={projectID} /> },
        { label: `Tasks (${taskCount})`, component: <Tasks setCount={setTaskCount} projectID={projectID} /> },
        { label: "Activities", component: <Activity projectID={projectID} /> },
        { label: "Meetings", component: <Meetings projectID={projectID} fetchTable={fetchTable} /> },
        { label: `Notes (${noteCount})`, component: <Notes fetch={fetchDetails} setCount={setNoteCount} projectID={projectID} /> },
        { label: `Members (${memberCount})`, component: <Addmembers setCount={setMemberCount} projectID={projectID} /> },
        { label: dropDownTabs[dropDownValueTab]?.label, component: dropDownTabs[dropDownValueTab]?.component },
    ];



    return (
        <>

            <div className='projectdetail-block'>
                <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid>
                        {ProjectName ? <h3 style={{ marginLeft: 5 }}>Project :  {ProjectName}</h3> : null}
                    </Grid>

                    <a onClick={handleClose} style={{ marginRight: 15, cursor: 'pointer', color: 'grey' }}><Close fontSize='small' /></a>
                </Grid>
                <Grid ml={1}>
                    {
                        stickyNote?.map((note, index) => (
                            <a key={index}>
                                <a style={{ color: 'grey' }}>{note?.title}</a>
                                <br />
                            </a>
                        ))
                    }
                </Grid>

                <Box sx={{ width: '100%', mt: 1 }}>
                    <Box display={'flex'} alignItems={'center'} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ ml: 2 }}>
                            {
                                allTabs?.map((obj, index) => (
                                    <Tab key={index} label={obj?.label} {...a11yProps(index)} />
                                ))
                            }
                        </Tabs>
                        <ArrowDropDown
                            style={{ cursor: 'pointer' }}
                            onClick={handleOpenPopover}
                            aria-describedby={id}
                        />
                        <Popover
                            id={id}
                            open={anchorEl}
                            anchorEl={anchorEl}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >{
                                dropDownTabs?.map((menus, index) => (
                                    <MenuItem onClick={() => handlePopoverChange(index)}>{menus.label}</MenuItem>
                                ))
                            }
                        </Popover>

                    </Box>
                    {
                        allTabs?.map((obj, index) => (
                            <TabPanel key={index} value={value} index={index}>
                                {obj?.component}
                            </TabPanel>
                        ))
                    }
                </Box>

            </div >
            <Divider />

            {/* <div style={{ width: '95%', display: 'flex', justifyContent: 'end', marginTop: 5 }}>
                <Button variant="contained" onClick={handleClose} style={{ width: 'fit-content', mr: 7 }}>
                    Cancel
                </Button>

            </div> */}
        </>
    );
}
