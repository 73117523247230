import React, { useEffect, useState } from "react";
import { useAppSettings } from "../../../hooks/use-app-settings";
import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { PreQualifiers } from "../../../api/Endpoints/PreQualifiers";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { Account } from "../../../api/Endpoints/Account";
import toast from "react-hot-toast";
import { ClientReport } from "../../../api/Endpoints/ClientReport";
import Delete from "../common/Popup/Delete";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { Employee } from "../../../api/Endpoints/Employee";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AsyncSelect from "react-select/async";
import CurrentDateInput from "../../form/CurrenDateInput";
import { useForm } from "react-hook-form";
import Create from "./create";
import { LeaveApplication } from "../../../api/Endpoints/LeaveApplication";
import ApproveLeaveModal from "./Modal";
const Leave = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm();

  const columns = [
    {
      field: "employee_name",
      headerName: "Employee Name",
      width: 150,
      renderCell: (params) => (
        <>
            {params.row.employee_user_id?.name || ""}
        </>
      ),
    },
    {
      field: "manager_approval_status",
      headerName: "Manager Approval Status",
      width: 300,
      renderCell: (params) => params.row.manager_approval_status,
    },
    {
      field: "hr_approval_status",
      headerName: "Hr Approval Status",
      width: 300,
      renderCell: (params) => params.row.hr_approval_status,
    },
    // {
    //   field: "hr_remarks",
    //   headerName: "Hr Remarks",
    //   width: 300,
    //   renderCell: (params) => params.row.hr_remarks,
    // },
    {
      field: "date_added",
      headerName: "Applied Date",
      width: 300,
      renderCell: (params) => params.row.date_added,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenModal(params.row.id)}
        >
          Approve
        </Button>
      ),
    },
  ];

  const [list, setList] = useState([]);
  const [listType, setListType] = useState();
  const [searchKeyword, setSearchKeyword] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editId, setEditId] = useState(undefined);
  const [Tabs, setTabs] = useState(false);
  const [ClientID, setClientID] = useState(0);
  const [deleteId, setDeleteId] = useState(false);
  const [from, setFrom] = useState(dayjs(new Date()));
  const [to, setTo] = useState(dayjs(new Date()));
  const [user, setUser] = useState(dayjs(new Date()));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedApproveId, setSelectedApproveId] = useState(null);

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handleDelete = (id) => {
    setDeleteId(id);
  };
  const handleEdit = (id) => {
    setRefresh(Math.random);
    setEditId(parseInt(id));
  };
  const fetchTable = () => {
    setLoading(true);
    LeaveApplication.get({ keyword: searchKeyword, page: Number(pageNumber) + 1 })
      .then((response) => {
        console.log("leave application",response.data);
        setList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const fetchEmployees = async (keyword) => {
    const response = await Employee.get({ keyword: keyword });
    console.log(response)
    return response.data.data?.data;
  };

  const handleUserSelect = (e) => {
    setUser(e?.id || "");
  };
  const handleOpenModal = (id) => {
    setSelectedApproveId(id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedApproveId(null);
  };

  useEffect(async () => {
    fetchTable();
  }, [searchKeyword, listType, pageNumber, user, watch("from"), watch("to")]);

  const handleUpdate = () => {
    setPageNumber();
    setPageNumber(0);
  };

  const handleCreate = () => {
    setEditId(0);
    setRefresh(Math.random);
  };


  return (
    <>


      <>
        <PageHeader title={"Leave"} />
        {/**/}

        <Grid
          container
          p={2}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
        >
          <Grid xs={3}>
            <AsyncSelect
              isClearable
              defaultOptions
              loadOptions={fetchEmployees}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.id}
              placeholder={<div>Select employee</div>}
              onChange={handleUserSelect}
            />
          </Grid>
          <Grid
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"space-between"}
          >
            <Grid width={"200px"} marginRight={3}>
              <CurrentDateInput
                control={control}
                name="from"
                value={watch("from")}
                label="From"
              />
            </Grid>
            <Grid width={"200px"} marginRight={3}>
              <CurrentDateInput
                control={control}
                name="to"
                value={watch("to")}
                label="To"
              />
            </Grid>{" "}
            <Create
              onNew={handleCreate}
              onUpdate={handleUpdate}
              editId={editId}
              setEditId={setEditId}
            />
          </Grid>
        </Grid>

        {/* <Card sx={{ m: 2, p: 2, display: 'flex', justifyContent: 'end' }} variant="outlined" >
                   
                </Card> */}
        <Card sx={{ m: 2 }} variant="outlined">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {typeof list.data === "object" && (
            <DataGrid
              paginationMode="server"
              rows={list?.data}
              columns={columns}
              page={pageNumber}
              pageSize={10}
              rowHeight={20}
              rowsPerPageOptions={[10]}
              rowCount={list?.total}
              onPageChange={handlePageChange}
              autoHeight
              density={"comfortable"}
            />
          )}
        {isModalOpen && (
        <ApproveLeaveModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          approveId={selectedApproveId}
          setApproveId={setSelectedApproveId}
          onUpdate={handleUpdate}
        />
      )}
        </Card>
      </>
      {/* )} */}
    </>
  );
};

export default Leave;
