// /hr/leave-application/index

import { get, post } from './../Config/config';

export const LeaveApplication = {

    get: (data) => get('/hr/leave-application/index', {params : data}),
    add: (data) => post('/hr/leave-application/create', data),

    // getDetails: (data) => get('demo/get-details', {params : data}),
    // add: (data) => post('demo/store', data),
    // update: (data) => post('demo/update', data),
    // remove: (data) => get('demo/delete', {params : data}),

}
