import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Slide,
  FormControl,
  MenuItem,
  Select
} from "@mui/material";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import TextInput from "../../form/TextInput";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import SelectX from "../../form/SelectX";
import moment from "moment";
import { ErrorMessage } from "@hookform/error-message";
import AddIcon from "@mui/icons-material/Add";
import { Account } from "../../../api/Endpoints/Account";
import DateInput from "../../form/DateInput";
import { Project } from "../../../api/Endpoints/Project";
import { Country } from "../../../api/Endpoints/Country";
import ReactSelector from "react-select";
import { Employee } from "../../../api/Endpoints/Employee";
import { Users } from "../../../api/Endpoints/Users";
import { LeaveApplication } from "../../../api/Endpoints/LeaveApplication";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Create = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    Controller,
    setValue,
    getValues,
    reset
  } = useForm({});
  const [open, setOpen] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedScopes, setSelectedScopes] = useState([]);
  const [employeeID, setEmployeeID] = useState(null);
  const [maangerId, setMaangerId] = useState(null);

  const handleClickOpen = () => {
    props.onNew();
  };
  const handleClose = () => {
    props.setEditId(undefined);
    setOpen(false);
  };

  //component variables
  const [formButtonStatus, setFormButtonStatus] = useState({
    label: "Submit",
    loading: false,
    disabled: false
  });
  const [alerts, setAlerts] = useState({
    type: "",
    message: "",
    active: false
  });

  // const fetchEmployees = (e) => {
  //     return Employee.get({ keyword: e }).then((response) => {
  //         if (response.data.data) {
  //             return response.data.data.data;
  //         } else {
  //             return [];
  //         }
  //     });
  // };

  const leaveType = [
    {
      value: 1,
      label: "Full Day"
    },
    { value: 2, label: "Half Day" }
  ];
  const fetchMe = (e) => {
    return Users.me({ keyword: e }).then((response) => {
      console.log("this is me", response.data.data);

      if (response.data.data) {
        setEmployeeID(response.data.data.employee_id);
      } else {
        return [];
      }
    });
  };
  const fetchManagerId = (empid) => {
    Employee.getEmployeeDetails({ id: empid }).then((response) => {
      console.log("this manager id", response.data.data?.user?.manager_id);
      setMaangerId(response.data.data?.user?.manager_id);
      setLoading(false);
    });
  };

  const onSubmit = (data) => {
    setFormButtonStatus({ ...formButtonStatus, loading: true });
    setAlerts({
      active: false,
      message: "Internal server error",
      type: "error"
    });

    let date = moment(data.start_date).format("YYYY-MM-DD");
    let enddate = moment(data.end_date).format("YYYY-MM-DD");

    let dataToSubmit = {
      id: props.editId,
      employee_user: employeeID,
      manager_id: maangerId,
      count: data.count,
      start_date: date,
      end_date: enddate,
      leave_type: data.attendance_type,
      leave_category: data.leave_type,
      reason: data.reason
    };

    let action;

    console.log("dataToSubmit", dataToSubmit);
    if (props.editId > 0) {
      action = LeaveApplication.add(dataToSubmit);
    } else {
      action = LeaveApplication.add(dataToSubmit);
    }
    action
      .then((response) => {
        setFormButtonStatus({
          label: "Submitted",
          loading: false,
          disabled: true
        });
        setAlerts({
          active: true,
          message: response.data.message,
          type: response.data.status
        });
        setFormButtonStatus({
          label: "Create",
          loading: false,
          disabled: false
        });
        toast.success(response.data.message);
        props.onUpdate();
        props.setEditId();
        setOpen(false);
        setTimeout(() => {
          setAlerts({});
        }, 2000);
      })
      .catch((errors) => {
        console.log(errors);
        toast.error("Internal server error");
        setAlerts({
          active: true,
          message: "Internal server error",
          type: "error"
        });
        setFormButtonStatus({
          label: "Create",
          loading: false,
          disabled: false
        });
      });
  };

  const fetchDetails = async () => {
    const response = await Project.getDetails({ project_id: props.editId });
    if (response.data.status === "success") {
      const data = response.data.data;
      console.log(data);

      setValue("project_name", data.name);
      setValue("description", data.description);
      if (data.accounts_id) {
        setValue("client_id", {
          id: data.accounts_id,
          name: data.account?.account_name
        });
      }
      if (!data.accounts_id) {
        setValue("client_id", "");
      }
      if (data.country_id) {
        setValue("country", {
          id: data.country?.id,
          name: data.country?.name
        });
      }
      setValue("start_date", data?.start_date);
    }
  };

  useEffect(() => {
    fetchMe();

    if (employeeID) {
      fetchManagerId(employeeID);
    }
  }, [employeeID]);

  useEffect(() => {
    if (parseInt(props.editId) > 0) {
      fetchDetails();
      setOpen(true);
    } else if (props.editId === 0) {
      console.log("truee");
      setOpen(true);
    }
  }, [props.editId]);

  // useEffect(() => {
  //     setRefresh(Math.random())
  // }, [watch('client_id')])

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        {props.icon ? (
          <AddIcon
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap"
            }}
          />
        ) : (
          "Apply Leave"
        )}
      </Button>

      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50%",
            height: "100%",
            position: "fixed",
            right: 0,
            top: 0,
            bottom: 0,
            m: 0,
            p: 0,
            borderRadius: 0,
            maxHeight: "100%"
          }
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {parseInt(props.editId) > 0 ? "Edit Project" : "Apply Leave"}
        </DialogTitle>
        <DialogContent>
          {!loading ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <ErrorMessage
                errors={errors}
                name="multipleErrorInput"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <p key={type}>{message}</p>
                  ))
                }
              />

              <Grid container>
                <Grid sx={{ p: 1 }} item xs={12} sm={6}>
                  <Grid item xs={12}>
                    <InputLabel
                      sx={{
                        color: "black"
                      }}
                    >
                      Attendance Type
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="leave-type-label"
                        id="leave-type"
                        value={watch("attendance_type") || ""}
                        onChange={(e) => setValue("attendance_type", e.target.value)}
                        displayEmpty
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="Full Day">Full Day</MenuItem>
                        <MenuItem value="Half Day">Half Day</MenuItem>
                      </Select>
                      <ErrorMessage
                        errors={errors}
                        name="leave_type"
                        render={({ message }) => (
                          <p style={{ color: "red" }}>{message}</p>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid sx={{ p: 1 }} item xs={12} sm={6}>
                  <InputLabel sx={{ color: "black" }}>Leave Type</InputLabel>
                  <FormControl fullWidth>
                    <Select
                      labelId="leave-category-label"
                      id="leave-category"
                      value={watch("leave_type") || ""}
                      onChange={(e) =>
                        setValue("leave_type", e.target.value)
                      }
                      displayEmpty
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="Casual Leave">Casual Leave</MenuItem>
                      <MenuItem value="Sick Leave">Compensatory Leave</MenuItem>
                      <MenuItem value="Special Leave">Special Leave</MenuItem>
                      {/* <MenuItem value="Religious Leave">Special Leave</MenuItem> */}
                    </Select>
                    <ErrorMessage
                      errors={errors}
                      name="leave_type"
                      render={({ message }) => (
                        <p style={{ color: "red" }}>{message}</p>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              
              <Grid container spacing={2} sx={{ display: "flex", p: 1 }}>
                <Grid item xs={12} sm={6}>
                  <DateInput
                    control={control}
                    name="start_date"
                    label="Start Date"
                    value={watch("start_date")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateInput
                    control={control}
                    name="end_date"
                    label="End Date"
                    value={watch("end_date")}
                  />
                </Grid>
              </Grid>

              <Grid sx={{ p: 1, mt: 1 }} item xs={12}>
                <TextInput
                  name="reason"
                  label="Reason"
                  control={control}
                  isMultiline
                  row={2}
                  value={watch("reason")}
                />
              </Grid>

              <Grid sx={{ p: 1, mb: 3 }} item xs={12}>
                <LoadingButton
                  loading={formButtonStatus.loading}
                  disabled={formButtonStatus.disabled}
                  variant="outlined"
                  type="submit"
                >
                  {formButtonStatus.label}
                </LoadingButton>
              </Grid>
            </form>
          ) : (
            <>Loading...</>
          )}
        </DialogContent>
        {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default Create;
