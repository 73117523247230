import React, { useEffect, useState } from 'react';
import moment from "moment";
import { Backdrop, Button, Card, CircularProgress, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { Activities } from "../../.././../../api/Endpoints/Activities";
import Create from "./create";
import DetailView from '../../../activities/DetailView';
import { ProjectScheduleAPI } from '../../../../../api/Endpoints/ProjectSchedule';
import ScheduleView from './View';
import { Edit } from '@mui/icons-material';
import DeleteIcon from '@material-ui/icons/Delete';
import Delete from '../../../common/Popup/Delete';


const ScheduleIndex = (props) => {

    const [editId, setEditId] = useState();
    const [refresh, setRefresh] = useState(0);
    const handleEdit = (id) => { setEditId(parseInt(id)); }

    const columns = [
        {

            field: 'title', headerName: 'title', width: 400,
            renderCell: (params) => <p style={{ cursor: 'pointer' }} onClick={() => ViewTab(params.row.id)}>{params.value}</p>

        },
        {
            field: 'assigned_to', headerName: 'Assigned To', width: 150,
            renderCell: (params) => <p>{params.value?.name || 'NA'}</p>
        },
        {
            field: 'start_date', headerName: 'Start Date', width: 150,
            renderCell: (params) => <p>{params.value ? moment(params.value)?.format('DD-MM-YYYY') : 'NA'}</p>
        },
        {
            field: 'due_date', headerName: 'Due Date', width: 150,
            renderCell: (params) => <p>{params.value ? moment(params.value)?.format('DD-MM-YYYY') : 'NA'}</p>
        },
        {
            field: 'status', headerName: 'Status', width: 180,
            renderCell: (params) => <p>{params.value}</p>
        },

        {
            field: 'action', headerName: '', width: 30, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <><a
                    style={{ marginLeft: 1, color: "blue", cursor: "pointer" }}
                    onClick={() => handleEdit(id)}> <Edit fontSize="small" /></a>
                </>;
            }
        },
        {
            field: 'delete', headerName: '', width: 30, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <><a
                    style={{ marginLeft: 1, color: "red", cursor: "pointer" }}
                    onClick={() => handleDelete(id)}> <DeleteIcon fontSize="small" /></a>
                </>;
            }
        },
    ]

    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [ID, setID] = useState(0)
    const [viewPage, setViewPage] = useState(false)
    const [loading, setLoading] = useState(false);


    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }


    const fetchTable = () => {
        setLoading(true);
        ProjectScheduleAPI.get({ project_id: props.projectID, keyword: searchKeyword, page: parseInt(pageNumber) + 1, }).then(response => {
            setList(response.data.data);
            setLoading(false);
        })
    }

    const handleUpdate = () => {
        if (pageNumber == 0) {
            setRefresh(Math.random * 1.2)
        } else {
            setPageNumber(0);
        }
    }

    const [deleteId, setdeleteId] = useState()
    const handleDelete = (id) => {
        setdeleteId(id)
    }

    const handleCreate = () => {
        setEditId(0);
    }
    const ViewTab = (id) => {
        console.log("id here", id);
        setViewPage(true)
        setID(id)
    }
    useEffect(async () => {
        fetchTable();
    }, [searchKeyword, listType, pageNumber, refresh])


    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }


    return (
        <>

            {
                deleteId &&
                <Delete
                    ID={deleteId}
                    setID={setdeleteId}
                    setDeletePopup={setdeleteId}
                    Callfunc={() => handleUpdate()}
                    url="/projects/project-module/delete?id="
                    title="Schedule"
                />}
            <div style={{ p: 2, display: "flex", justifyContent: "end", alignItems: 'center' }} variant="outlined">


                <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} ProjectID={props.projectID} setEditId={setEditId} />

                {viewPage && <ScheduleView ID={ID} setID={setID} />}


            </div >


            <Card sx={{ m: 2 }} variant="outlined">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                {typeof list.data === "object" &&
                    <DataGrid
                        paginationMode="server"
                        rows={list?.data}
                        columns={columns}
                        page={pageNumber}
                        pageSize={10}
                        rowHeight={20}
                        rowsPerPageOptions={[10]}
                        rowCount={list.total}
                        onPageChange={handlePageChange}
                        autoHeight
                        density={"comfortable"}
                    />
                }
            </Card>
        </>
    );
};

export default ScheduleIndex;
