import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {
    CircularProgress,
    Box,
    Grid,
    Typography,
    Divider,
} from "@mui/material";
import { useEffect, useState } from 'react';
import { ProjectScheduleAPI } from '../../../../../api/Endpoints/ProjectSchedule';

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        border: '1px solid #ccc',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
        width: '100%',
    },
}));

const ScheduleDetail = ({ ID }) => {
    const classes = useStyles();

    const [Loading, setLoading] = useState(false);
    const [Data, setData] = useState();

    useEffect(() => {
        fetchDetails();
    }, []);

    const fetchDetails = async () => {
        setLoading(true);
        let task = await ProjectScheduleAPI.getDetails({ id: ID });
        if (task.data.status === "success") {
            let data = task.data.data;
            setData(data);
        }
        setLoading(false);
    };

    return (
        <>
            {Loading ? (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '30vh'
                }}>
                    <CircularProgress className='ld-circle' />
                </Box>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Title:</Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>{Data?.title}</Typography>
                        </Grid>
                        <Box><Divider className={classes.divider} /></Box>
                    </Grid>
                    <Box><Divider className={classes.divider} /></Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>Project Name:</Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>{Data?.project?.name}</Typography>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>Assigned To:</Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>{Data?.assigned_to?.name || 'NA'}</Typography>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>Start Date:</Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>{moment(Data?.start_date).format("DD-MM-YYYY")}</Typography>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>Due Date:</Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>{moment(Data?.due_date).format("DD-MM-YYYY")}</Typography>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>Actual Completion Date:</Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>{moment(Data?.actual_completion_date).format("DD-MM-YYYY")}</Typography>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>Status:</Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>{Data?.status}</Typography>
                            <Divider className={classes.divider} /> 
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>Created By:</Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>{Data?.created_user?.name}</Typography>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>Created At:</Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>{moment(Data?.created_at).format("DD-MM-YYYY")}</Typography>
                            <Divider className={classes.divider} /> 
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Box><Divider className={classes.divider} /></Box>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Remarks:</Typography>
                            <Typography variant="body2" style={{ paddingTop: 10, fontSize: '16px' }}>{Data?.remarks}</Typography>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default ScheduleDetail;