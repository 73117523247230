import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
  FormControl,
  MenuItem,
  Select
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../../common/page-header";
import { Activities } from "../../../../api/Endpoints/Activities";
import Create from "./create";
import DetailView from "./DetailView";
import ReactSelector from "react-select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Employee } from "../../../../api/Endpoints/Employee";
import { Project } from "../../../../api/Endpoints/Project";
import { UserIdContext } from "../../../../contexts/UserID/UserID";
import CurrentDateInput from "../../../form/CurrenDateInput";
import { ReportsApi } from "../../../../api/Endpoints/Reports";
import moment from "moment";
import SelectX from "../../../form/SelectX";
import { Link } from "react-router-dom";

const scheme = yup.object().shape({});

const PeriodicReporting = (props) => {
  const {
    watch,
    formState: { errors },
    control,
    setValue
  } = useForm({ resolver: yupResolver(scheme) });
  const [editId, setEditId] = useState();
  const [refresh, setRefresh] = useState(0);
  const [listByProject, setListByProject] = useState(false);
  const [detailViewId, setDetailViewId] = useState(false);
  const [projects, setProjects] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedProjectID, setSelectedProjectID] = useState(null);
  const [status, setStatus] = useState("");
  const [list, setList] = useState([]);
  const [listType, setListType] = useState();
  const [searchKeyword, setSearchKeyword] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState("");
  const [type, setType] = useState("");
  

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const columns = [
    {
      field: "name",
      headerName: "Title",
      width: 180,
      renderCell: (params) => (
        <p style={{ cursor: "pointer", color: "blue" }}>{params.row.name}</p>
      )
    },
    {
      field: "activities",
      headerName: "Submitted Date",
      width: 170,
      renderCell: (params) => (
        <p style={{ cursor: "pointer" }}>{params.value}</p>
      )
    },
    {
      field: "tasks_created",
      headerName: "Report End Date",
      width: 170,
      renderCell: (params) => <p>{params.value}</p>
    },
    {
      field: "tasks_worked",
      headerName: "Project",
      width: 170,
      renderCell: (params) => {
        return <p>{params.value}</p>;
      }
    },
    {
      field: "tasks_delyed",
      headerName: "Submitted By",
      width: 170,
      renderCell: (params) => <p>{params.value}</p>
    },
    {
      field: "hours_reported",
      headerName: "Reviewed By",
      width: 190,
      renderCell: (params) => <p>{params.value}</p>
    }
  ];

  const handleListingSearchChange = (keyword) => {
    setSearchKeyword(keyword);
    setPageNumber(0);
  };
  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const fetchTable = () => {
    setLoading(true);
    console.log(searchKeyword);
    ReportsApi.getActivity({
      page: parseInt(pageNumber) + 1,
      limit: 50
    }).then((response) => {
      console.log(response.data);
      setList(response.data);
      setLoading(false);
    });
  };

  const fetchProjects = (e) => {
    Project.get({ keyword: e }).then((response) => {
      console.log(response);
      if (typeof response.data.data.data !== "undefined") {
        setProjects(response.data.data.data);
      } else {
        return [];
      }
    });
  };

  const fetchEmployees = (e) => {
    console.log(e);
    Employee.get({ keyword: e, limit: 100 }).then((response) => {
      // console.log(response.data.data.data);
      if (typeof response.data.data.data !== "undefined") {
        setEmployees(response.data.data.data);
      } else {
        return [];
      }
    });
  };

  const selectProject = (project_id) => {
    console.log(project_id);
    setSelectedProjectID(project_id?.id);
    setValue("projects", project_id?.name || "");
  };

  useEffect(() => {
    fetchTable();
    fetchProjects();
    fetchEmployees();
  }, [
    searchKeyword,
    listType,
    pageNumber,
    selectedProjectID,
    watch("from"),
    watch("to")
  ]);

  return (
    <>
      {detailViewId && (
        <DetailView id={detailViewId} handleClose={setDetailViewId} />
      )}
      <PageHeader title={"Periodic Report"} />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
        variant="outlined"
      >
        <Grid item display={"flex"} alignItems={"center"}>
          <Grid sx={{ width: 200 }} mr={2}>
            <SelectX
              options={fetchEmployees}
              control={control}
              placeholder="Employees"
              name="employees"
              value={watch("employees")}
            />
          </Grid>
          <Grid sx={{ width: 200 }} mr={2}>
            <ReactSelector
              placeholder="Projects"
              // onInputChange={handleInputChange}

              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              options={projects}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={projects.find(
                (options) => options.name === watch("projects")
              )}
              name="projects"
              isClearable
              defaultValue={watch("projects")}
              onChange={(selectedOption) => selectProject(selectedOption)}
            />
          </Grid>
          <Grid sx={{ width: 200 }} mr={2}>
            <FormControl fullWidth>
              <Select
                labelId="status-select-label"
                value={status}
                onChange={handleChange}
                displayEmpty
                sx={{ color: "gray" }}
              >
                <MenuItem value="" disabled sx={{ color: "grey" }}>
                  Status
                </MenuItem>
                <MenuItem value="Draft">Draft</MenuItem>
                <MenuItem value="Pending_Review">Pending Review</MenuItem>
                <MenuItem value="Approved">Approved</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid sx={{ width: 200 }} mr={2}>
            <FormControl fullWidth>
              <Select
                labelId="period-select-label"
                value={period}
                onChange={handlePeriodChange}
                displayEmpty
                sx={{ color: "gray" }}
              >
                <MenuItem value="" disabled sx={{ color: "grey" }}>
                  Select Period
                </MenuItem>
                <MenuItem value="Weekly">Weekly</MenuItem>
                <MenuItem value="Monthly">Monthly</MenuItem>
                <MenuItem value="Quarterly">Quarterly</MenuItem>
                <MenuItem value="Annual">Annual</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Type Select */}
          <Grid sx={{ width: 200 }} mr={2}>
            <FormControl fullWidth>
              <Select
                labelId="type-select-label"
                value={type}
                onChange={handleTypeChange}
                displayEmpty
                sx={{ color: "gray" }}
              >
                <MenuItem value="" disabled sx={{ color: "gray" }}>
                  Type
                </MenuItem>
                <MenuItem value="Internal">Internal</MenuItem>
                <MenuItem value="Client">Client</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item>
          <Button variant="contained">
            <Link
              style={{ color: "inherit", textDecoration: "none" }}
              to={"/dashboard/reporting/create-report"}
            >
              Create Report
            </Link>
          </Button>
        </Grid>
      </Grid>
      <Card sx={{ m: 2, height: 450 }} variant="outlined">
        {typeof list?.data === "object" && (
          <DataGrid
            paginationMode="server"
            rows={list?.data}
            columns={columns}
            page={pageNumber}
            pageSize={50}
            rowHeight={20}
            rowsPerPageOptions={[50]}
            rowCount={list?.meta?.total}
            onPageChange={handlePageChange}
            getRowId={(row) => row.user_id}
            density={"comfortable"}
          />
        )}
      </Card>
    </>
  );
};

export default PeriodicReporting;
