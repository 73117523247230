import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    Select,
    Slide, TextField, Typography
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { ErrorMessage } from "@hookform/error-message";
import ReactSelector from 'react-select';
import { Task } from '../../../../../api/Endpoints/Task';
import { Project } from '../../../../../api/Endpoints/Project';
import { Activities } from '../../../../../api/Endpoints/Activities';
import { Users } from '../../../../../api/Endpoints/Users';
import { Auth } from '../../../../../api/Endpoints/Auth';
import SelectX from '../../../../form/SelectX';
import TextInput from '../../../../form/TextInput';
import LoadingScreen from '../../../../form/progress';
import moment from "moment";
import { SheduleStatus } from '../../../../../commonData/schedule_status';
import DateInput from '../../../../form/DateInput';
import { Employee } from '../../../../../api/Endpoints/Employee';
import { ProjectScheduleAPI } from '../../../../../api/Endpoints/ProjectSchedule';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({


    title: yup.string().required(),
    // durationHours: yup.number().nullable(),
    task: yup.string(),
    projects_id: yup.object().nullable()
})

const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });

    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const handleClickOpen = () => { props.onNew(); };
    const handleClose = () => { props?.setEditId(); setOpen(false); };


    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    const fetchProjects = (e) => {
        return Project.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }
    const fetchEmployees = (e) => {
        return Employee.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }

    const onSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })

        let dataToSubmit = {
            ...(props?.editId ? { id: props?.editId } : {}),
            project_id: data.projects_id?.id,
            title: data.title,
            remarks: data.description,
            ...(data.start_date ? { start_date: moment(data.start_date).format('YYYY-MM-DD') } : {}),
            ...(data.due_date ? { due_date: moment(data.due_date).format('YYYY-MM-DD') } : {}),
            assigned_to: data.assign_to?.id,
            ...(data.actual_completion_date ? { actual_completion_date: moment(data.actual_completion_date).format('YYYY-MM-DD') } : {}),
            status: data?.status,
            // status_note: data?.status_note,
            // created_at: moment().format('YYYY-MM-DD'),

        };

        let action;
        if (props.editId > 0) {
            action = ProjectScheduleAPI.update(dataToSubmit);
        } else {
            action = ProjectScheduleAPI.add(dataToSubmit);
        }

        action.then(response => {
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Submit", loading: false, disabled: false });
            toast.success(response.data.message)
            props.onUpdate();
            props?.setEditId();
            // setOpen(false);
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Submit", loading: false, disabled: false });
        })
    }

    const fetchDetails = async () => {
        setLoading(true)
        let task = await ProjectScheduleAPI.getDetails({ id: props.editId });
        if (task.data.status === "success") {
            let data = task.data.data;
            console.log(data)
            setValue('title', data.title);
            setValue('projects_id', data?.project);
            // setValue('durationMinutes', data.duration_minutes);
            // setValue('durationHours', data.duration_hours);
            setValue('start_date', data.start_date);
            setValue('due_date', data.due_date);
            setValue('actual_completion_date', data.actual_completion_date);
            setValue('status', data.status);
            setValue('description', data.remarks);
        }
        setLoading(false)
    };

    const getProjectDetails = () => {
        Project.getDetails({ project_id: props.ProjectID }).then((response) => {
            let data = response.data.data
            setValue('projects_id', data);
        })
    }

    const fetchUser = async () => {

      
    }
 
    // console.log(currentUser);
    useEffect(() => {
        setRefresh(Math.random())
    }, [watch('projects_id')])


    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            setOpen(true);
            fetchDetails();
        } else if (parseInt(props.editId) === 0) {
            setOpen(true);
            // fetchUser()
            getProjectDetails()

        } else {
            setOpen(false);
        }

    }, [props.editId])

    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                Add Shedule
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{parseInt(props.editId) > 0 ? "Edit Shedule" : "Create Shedule"}</DialogTitle>

                {!loading ? (
                    <>
                        <DialogContent>
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <ErrorMessage
                                    errors={errors}
                                    name="multipleErrorInput"
                                    render={({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(([type, message]) => (
                                            <p key={type}>{message}</p>
                                        ))
                                    }
                                />

                                <Grid sx={{ mt: 2 }} item xs={12}>
                                    <TextInput control={control} name="title"
                                        label="Title"
                                        value={watch('title')} />
                                </Grid>

                                <Grid container spacing={1} sx={{ mt: 2 }}>
                                    <Grid item xs={6}>
                                        <SelectX
                                            key={watch('projects_id')}
                                            label={"Choose project"}
                                            options={fetchProjects}
                                            control={control}
                                            error={errors?.projects_id?.id ? errors?.projects_id?.id?.message : false}
                                            error2={errors?.projects_id?.message ? errors?.projects_id?.message : false}
                                            name={'projects_id'}
                                            defaultValue={watch('projects_id')}
                                            isClearable={false}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <SelectX
                                            label={"Assigned To"}
                                            options={fetchEmployees}
                                            control={control}
                                            error={errors?.projects_id?.id ? errors?.projects_id?.id?.message : false}
                                            error2={errors?.projects_id?.message ? errors?.projects_id?.message : false}
                                            name={'assign_to'}
                                            defaultValue={watch('assign_to')}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1} sx={{ mt: 2 }}>
                                    <Grid item xs={6}>
                                        <DateInput
                                            placeholder={'date'}
                                            control={control}
                                            name="start_date"
                                            label="Start Date"
                                            value={watch('start_date')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DateInput
                                            placeholder={'date'}
                                            control={control}
                                            name="due_date"
                                            label="Due Date"
                                            value={watch('due_date')}
                                        />
                                    </Grid>
                                </Grid>



                                <Grid container spacing={1} sx={{ mt: 2 }}>
                                    <Grid item xs={6}>
                                        <DateInput
                                            placeholder={'date'}
                                            control={control}
                                            name="actual_completion_date"
                                            label="Actual Completion Date"
                                            value={watch('actual_completion_date')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel sx={{
                                            color: "black",
                                            fontSize: "13px",
                                            pb: .5,
                                            fontWeight: 500
                                        }}>Status</InputLabel>
                                        <ReactSelector
                                            styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                            options={SheduleStatus}
                                            getOptionLabel={option => option.label}
                                            getOptionValue={option => option.label}
                                            value={
                                                SheduleStatus.filter(options =>
                                                    options.label === watch('status')
                                                )
                                            }
                                            name='status'
                                            isClearable={true}
                                            defaultValue={(watch('status'))}
                                            onChange={(selectedOption) => setValue('status', selectedOption?.label)}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="status"
                                            render={({ message }) => (
                                                <p style={{ marginTop: -1, fontSize: '12px', color: "red" }} className="error-message">{message}</p>
                                            )}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid sx={{ mt: 2 }} item xs={12}>
                                    <InputLabel sx={{
                                        color: "black",
                                        pb: .5
                                    }}>Remarks </InputLabel>
                                    <TextField
                                        {...register('description')}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        sx={{ width: '100%', }}
                                    // required
                                    />
                                </Grid>

                                <Grid sx={{ mt: 2 }} item xs={12}>
                                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                </Grid>

                            </form>

                        </DialogContent>
                        {/* <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions> */}
                    </>
                ) : (

                    <Box className="loadingScreen" >
                        <LoadingScreen />
                    </Box>


                )}
            </Dialog>
        </div >
    );
};

export default Create;
