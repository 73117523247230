import React, { useEffect, useState } from "react";
import { useAppSettings } from "../../../hooks/use-app-settings";
import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { PreQualifiers } from "../../../api/Endpoints/PreQualifiers";
import { DataGrid } from "@mui/x-data-grid";
import PageHeader from "../common/page-header";
import { Account } from "../../../api/Endpoints/Account";
import toast from "react-hot-toast";
import { ClientReport } from "../../../api/Endpoints/ClientReport";
import Delete from "../common/Popup/Delete";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { Employee } from "../../../api/Endpoints/Employee";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AsyncSelect from "react-select/async";
import CurrentDateInput from "../../form/CurrenDateInput";
import { useForm } from "react-hook-form";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Create from "./create";
const WebsiteManagment = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm();


  const columns = [
    // { field: 'id', headerName: 'ID' },
    {
      field: "report_name",
      headerName: "Report Name",
      width: 150,
      renderCell: (params) => (
        <>
          <Button
            size="small"
            style={{ marginLeft: 1 }}
            onClick={() => viewTabs(params.id)}
          >
            {params.row.report_name}
          </Button>
        </>
      ),
    },
    {
      field: "project",
      headerName: "Porject Name",
      width: 300,
      renderCell: (params) => params.row.project?.name,
    },
    {
      field: "document",
      headerName: "Document",
      width: 300,
      renderCell: (params) => (
        <a
          target="_blank"
          href={`https://dashboard.omnisellcrm.com/${params?.row?.document}`}
        >
          {params.row.document.slice(43)}
        </a>
      ),
    },

    // { field: 'company_name', headerName: 'Company Name', width: 200 },
    // { field: 'remarks', headerName: 'remarks', width: 300 },
    // { field: 'created_at', headerName: 'Created On', width: 150, renderCell: (params) => { return moment(params.value).format('MMM Mo dddd') } },

    {
      field: "Edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => (
        <>
          <a
            variant="contained"
            size="small"
            style={{ marginLeft: 1,cursor:'pointer',color:'blue' }}
            onClick={() => handleEdit(params.id)}
          >
            <EditIcon fontSize="small" />
          </a>
        </>
      ),
    },
    {
      field: "Delete",
      headerName: "Delete",
      width: 100,
      renderCell: (params) => (
        <>
          <a
            variant="outlined"
            color="error"
            size="small"
            style={{ marginLeft: 1,cursor:'pointer'}}
            onClick={() => handleDelete(params.id)}
          >
            <DeleteIcon fontSize="small" color="error" /> 
          </a>
        </>
      ),
    },
  ];

  const [list, setList] = useState([]);
  const [listType, setListType] = useState();
  const [searchKeyword, setSearchKeyword] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editId, setEditId] = useState(undefined);
  const [Tabs, setTabs] = useState(false);
  const [ClientID, setClientID] = useState(0);
  const [deleteId, setDeleteId] = useState(false);
  const [from, setFrom] = useState(dayjs(new Date()));
  const [to, setTo] = useState(dayjs(new Date()));
  const [user, setUser] = useState(dayjs(new Date()));
  const handleListingSearchChange = (keyword) => {
    setSearchKeyword(keyword);
    setPageNumber(0);
  };
  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handleLeadClick = (leadId) => {
    props.onPreQualifierIdChange(leadId);
    setSelectedPreQualifierId(leadId);
  };

  const handleDelete = (id) => {
    setDeleteId(id);
  };
  const handleEdit = (id) => {
    setRefresh(Math.random);
    setEditId(parseInt(id));
  };
  const fetchTable = () => {
    setLoading(true);
    ClientReport.get({ keyword: searchKeyword, page: Number(pageNumber) + 1 })
      .then((response) => {
        console.log(response);
        setList(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const fetchEmployees = async (keyword) => {
    const response = await Employee.get({ keyword: keyword });
    console.log(response)
    return response.data.data?.data;
  };

  const handleUserSelect = (e) => {
    setUser(e?.id || "");
  };

  useEffect(async () => {
    fetchTable();
  }, [searchKeyword, listType, pageNumber, user, watch("from"), watch("to")]);

  const handleUpdate = () => {
    setPageNumber();
    setPageNumber(0);
  };

  const handleCreate = () => {
    setEditId(0);
    setRefresh(Math.random);
  };
  const viewTabs = (id) => {
    setClientID(id);
    setTabs(true);
  };

  return (
    <>
      {/* {Tabs ? (<BasicTabs setTabs={setTabs} ClientID={ClientID} />
            ) : ( */}

      {deleteId && (
        <Delete
          ID={deleteId}
          setID={setDeleteId}
          setDeletePopup={setDeleteId}
          Callfunc={() => fetchTable()}
          url="/projects/client-reports/delete?id="
          title="Report"
        />
      )}

      <>
        <PageHeader title={"Websites & Landing Pages"} />
        {/**/}

        <Grid
          container
          p={2}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
        >
          <Grid xs={3}>
            <AsyncSelect
              isClearable
              defaultOptions
              loadOptions={fetchEmployees}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.id}
              placeholder={<div>Select Project</div>}
              onChange={handleUserSelect}
            />
          </Grid>
          <Grid
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"space-between"}
          >
            <Grid width={"200px"} marginRight={3}>
              <CurrentDateInput
                control={control}
                name="from"
                value={watch("from")}
                label="From"
              />
            </Grid>
            <Grid width={"200px"} marginRight={3}>
              <CurrentDateInput
                control={control}
                name="to"
                value={watch("to")}
                label="To"
              />
            </Grid>{" "}
            <Create
              onNew={handleCreate}
              onUpdate={handleUpdate}
              editId={editId}
              setEditId={setEditId}
            />
          </Grid>
        </Grid>

        {/* <Card sx={{ m: 2, p: 2, display: 'flex', justifyContent: 'end' }} variant="outlined" >
                   
                </Card> */}
        <Card sx={{ m: 2 }} variant="outlined">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {typeof list.data === "object" && (
            <DataGrid
              paginationMode="server"
              rows={list?.data}
              columns={columns}
              page={pageNumber}
              pageSize={10}
              rowHeight={20}
              rowsPerPageOptions={[10]}
              rowCount={list?.total}
              onPageChange={handlePageChange}
              autoHeight
              density={"comfortable"}
            />
          )}
        </Card>
      </>
      {/* )} */}
    </>
  );
};

export default WebsiteManagment;
