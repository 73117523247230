import React, { useState } from "react";
import {
  Modal,
  Grid,
  Card,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  InputLabel
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import moment from "moment";
import { LeaveApplication } from "../../../api/Endpoints/LeaveApplication";

const approvalStatusOptions = [
  { value: "Not Reviewed", label: "Not Reviewed" },
  { value: "Approved", label: "Approved" },
  { value: "Rejected", label: "Rejected" },
  { value: "Pending", label: "Pending" },
];

const ApproveLeaveModal = ({ isOpen, setIsOpen, approveId, setApproveId, onUpdate }) => {
  const { control, watch, handleSubmit } = useForm();
  const [formButtonStatus, setFormButtonStatus] = useState({
    label: "Submit",
    loading: false,
    disabled: false,
  });
  const [alerts, setAlerts] = useState({
    type: "",
    message: "",
    active: false,
  });

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = (data) => {
    setFormButtonStatus({ ...formButtonStatus, loading: true });
    setAlerts({
      active: false,
      message: "Internal server error",
      type: "error",
    });

    const dataToSubmit = {
      id: approveId,
      hr_approval_status: data.hr_approval_status,
      hr_review_date: moment(new Date()).format("YYYY-MM-DD"),
      hr_remarks: data.hr_remarks,
      date_added: new Date(),
    };

    let action;

    if (approveId > 0) {
      action = LeaveApplication.update(dataToSubmit);
    }

    action
      .then((response) => {
        setFormButtonStatus({
          label: "Submitted",
          loading: false,
          disabled: true,
        });
        setAlerts({
          active: true,
          message: response.data.message,
          type: response.data.status,
        });
        toast.success(response.data.message);
        onUpdate();
        setApproveId();
        closeModal();
        setTimeout(() => {
          setAlerts({});
        }, 2000);
      })
      .catch((errors) => {
        toast.error("Internal server error");
        setAlerts({
          active: true,
          message: "Internal server error",
          type: "error",
        });
        setFormButtonStatus({
          label: "Create",
          loading: false,
          disabled: false,
        });
      });
  };

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh", padding: 20 }}
      >
        <Card
          sx={{
            p: 4,
            width: "60%",
            maxWidth: "800px",
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          <Typography variant="h5" gutterBottom>
            Approve Leave
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth sx={{ pt: 2 }}>
                  <FormLabel>Manager Approval Status</FormLabel>
                  <Controller
                    name="manager_approval_status"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <RadioGroup {...field} sx={{ flexDirection: "row" }}>
                        {approvalStatusOptions.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          
                          />
                        ))}
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Manager Remarks</InputLabel>
                <Controller
                  name="manager_remarks"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline
                      fullWidth
                      rows={4}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="error" onClick={closeModal}>
                  Close
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Modal>
  );
};

export default ApproveLeaveModal;
